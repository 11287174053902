<template>
    <div>
        <div class="mt-1 pa-2 " style="max-height: 400px"> 
            <h3 class=" elevation-24 primary darken-2 pa-2 white--text font-weight-bold text-xs-left">({{requestedTransactions.length}}) Transactions So Far</h3>
            <v-divider></v-divider>
            <template class="" v-for="item in requestedTransactions">
                <v-hover v-if="item.status_code == 'Pending'" :key="item.id">
                    <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 4 : 12}`" :to="'/market_place/shopping_bag/items/transactions/'+item.id" color="white" style="border-left: 5px solid #2d79ed!important; cursor: pointer">
                        <v-card-text class="pt-2" style="font-size: smaller">
                            <h3 class="blue--text">
                                <span class="font-weight-bold blue--text">Transaction Order: {{item.order_id}}</span>
                            </h3> 
                            <v-layout row wrap>
                                <v-flex xs6 text-xs-left>
                                    <span class="dark--text font-weight-bold">Total Cost:</span><span> {{item.total_cost}} FCFA</span>
                                </v-flex>
                                <v-flex xs6 text-xs-right>
                                    <span class="dark--text font-weight-bold ">Total Paid:</span><span> {{item.total_paid}} F CFA</span>
                                </v-flex>

                                <v-flex xs4 text-xs-left v-if="item.status_code == 'Pending'">
                                    <span class="dark--text font-weight-bold">Status:</span><span class="red--text"> {{item.status_code}}</span>
                                </v-flex>
                                <v-flex xs4 text-xs-left v-if="item.status_code == 'Payment Validated'">
                                    <span class="dark--text font-weight-bold">Status:</span><span class="teal--text"> {{item.status_code}}</span>
                                </v-flex>
                                <v-flex xs4 text-xs-left v-if="item.status_code == 'Interrupted'">
                                    <span class="dark--text font-weight-bold">Status:</span><span class="red--text"> {{item.status_code}}</span>
                                </v-flex>

                                <v-flex xs8 text-xs-right>
                                    <span class="dark--text font-weight-bold ">Date:</span><span> {{item.get_date_str}}</span>
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                    <v-divider></v-divider>
                    </v-card>
                </v-hover>
                <v-hover v-else-if="item.status_code == 'Payment Validated' || item.status_code == 'Delivered' " :key="item.id">
                    <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 4 : 12}`" color="white" :to="'/market_place/shopping_bag/items/transactions/'+item.id" style="border-left: 5px solid green!important; cursor: pointer">
                        <v-card-text class="pt-2" style="font-size: smaller">
                            <h3 class="teal--text">
                                <span class="font-weight-bold teal--text">Transaction Order: {{item.order_id}}</span>
                            </h3> 
                            <v-layout row wrap>
                                <v-flex xs6 text-xs-left>
                                    <span class="dark--text font-weight-bold">Total Cost:</span><span> {{item.total_cost}} FCFA</span>
                                </v-flex>
                                <v-flex xs6 text-xs-right>
                                    <span class="dark--text font-weight-bold ">Total Paid:</span><span> {{item.total_paid}} F CFA</span>
                                </v-flex>

                                <v-flex xs4 text-xs-left v-if="item.status_code == 'Pending'">
                                    <span class="dark--text font-weight-bold">Status:</span><span class="red--text"> {{item.status_code}}</span>
                                </v-flex>
                                <v-flex xs4 text-xs-left v-if="item.status_code == 'Payment Validated'">
                                    <span class="dark--text font-weight-bold">Status:</span><span class="teal--text"> {{item.status_code}}</span>
                                </v-flex>
                                <v-flex xs4 text-xs-left v-if="item.status_code == 'Interrupted'">
                                    <span class="dark--text font-weight-bold">Status:</span><span class="red--text"> {{item.status_code}}</span>
                                </v-flex>

                                <v-flex xs8 text-xs-right>
                                    <span class="dark--text font-weight-bold ">Date:</span><span> {{item.get_date_str}}</span>
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                    <v-divider></v-divider>
                    </v-card>
                </v-hover>
                <v-hover v-else-if="item.status_code == 'Interrupted'" :key="item.id">
                    <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 4 : 12}`" color="white" :to="'/market_place/shopping_bag/items/transactions/'+item.id" style="border-left: 5px solid red!important; cursor: pointer">
                        <v-card-text class="pt-2" style="font-size: smaller">
                            <h3 class="red--text">
                                <span class="font-weight-bold red--text">Transaction Order: {{item.order_id}}</span>
                            </h3> 
                            <v-layout row wrap>
                                <v-flex xs6 text-xs-left>
                                    <span class="dark--text font-weight-bold">Total Cost:</span><span> {{item.total_cost}} FCFA</span>
                                </v-flex>
                                <v-flex xs6 text-xs-right>
                                    <span class="dark--text font-weight-bold ">Total Paid:</span><span> {{item.total_paid}} F CFA</span>
                                </v-flex>

                                <v-flex xs4 text-xs-left v-if="item.status_code == 'Pending'">
                                    <span class="dark--text font-weight-bold">Status:</span><span class="red--text"> {{item.status_code}}</span>
                                </v-flex>
                                <v-flex xs4 text-xs-left v-if="item.status_code == 'Payment Validated'">
                                    <span class="dark--text font-weight-bold">Status:</span><span class="teal--text"> {{item.status_code}}</span>
                                </v-flex>
                                <v-flex xs4 text-xs-left v-if="item.status_code == 'Interrupted'">
                                    <span class="dark--text font-weight-bold">Status:</span><span class="red--text"> {{item.status_code}}</span>
                                </v-flex>

                                <v-flex xs8 text-xs-right>
                                    <span class="dark--text font-weight-bold ">Date:</span><span> {{item.get_date_str}}</span>
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                    <v-divider></v-divider>
                    </v-card>
                </v-hover>
            </template>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                requestedTransactions: [],
            }
        },

        mounted(){
            // executes these after the page has been mounted
            document.title = "PettyCash Market Place | Transactions"
            this.getTransactions()
            this.$store.commit('setCloseURL', "/market_place/shopping_bag/items/my_items")
        },
        methods: {
            
            async getTransactions(){
                this.$store.commit('setIsLoading', true)
                
                await axios
                    .get('/api/v1/market_place/get/user/requested/bills')
                    .then(response => {
                        this.requestedTransactions = response.data  // get the data and fill into operators
                        console.log(this.requestedTransactions)
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },

        },
    }
</script>

<style scoped>
    blink {
    -webkit-animation: 4s linear infinite condemned_blink_effect; /* for Safari 4.0 - 8.0 */
    animation: 4s linear infinite condemned_blink_effect;
    }

    /* for Safari 4.0 - 8.0 */
    @-webkit-keyframes condemned_blink_effect {
    0% {
        visibility: hidden;
    }
    50% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
    }

    @keyframes condemned_blink_effect {
    0% {
        visibility: hidden;
    }
    50% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
    }

</style>
